<template>
       <GhmDataTable
          :dataList="dataList"
          :tableType="APP_DATATABLE_TYPE_DATA_LIST"
          :totalNumberOfItemsAvailable="totalNumberOfItemInDB"
          :itemsPerPageProps="itemsPerPage"
          :itemsPerPageOptionsProps="itemsPerPageOptions"
          @OnChangeOfOptionEmitter="optionPaginationEmitter" >
          <template v-slot:addNewRowButton>
            {{ $t('admin.logs.applicationLog') }}
          </template>
     </GhmDataTable>
</template>
<script>
import { mapActions } from 'vuex';
import Constants from '@/constants';
import GhmDataTable from '@/components/app/GhmDataTable.vue';
import ItemsPerPageMixin from '@/plugins/ItemsPerPageMixin';

export default {

  data() {
    return {
      pageTitle: 'app.menu.logs',
      dataList: [],
      totalNumberOfItemInDB: 0,
      APP_DATATABLE_TYPE_DATA_LIST: Constants.APP_DATATABLE_TYPE_DATA_LIST,
    };
  },

  components: {
    GhmDataTable,
  },

  mixins: [ItemsPerPageMixin],

  async mounted() {
    this.setPageTitleOfThisPage();

    await this.getTotalCountForApplicationLogsFromDB();

    await this.calculateItemsPerPage(this.totalNumberOfItemInDB);

    /*
    console.log('=========== mounted : application  log START ===========');
    // initialze list of all available datasource
    await this.fetchAllApplicationLogsFromDB({
      pageNo: 1,
      pageSize: 10,
      sortBy: 'createdDate',
    });
    console.log('=========== mounted : application  log END ===========');
     */
  },

  methods: {
    ...mapActions(['setPageTitle', 'findAllApplicationLogs', 'findTotalCountForApplicationLogs']),

    setPageTitleOfThisPage() {
      console.log('--------------user queries page tityle METHOD setPageTitleOfThisPage ---------------');
      this.setPageTitle(this.pageTitle);
    },

    /**
     * Get all list of application logs from database.
     */
    async getTotalCountForApplicationLogsFromDB() {
      console.log(
        '------------ START : fetching getTotalCountForApplicationLogsFromDB ********** ',
      );
      this.totalNumberOfItemInDB = await this.findTotalCountForApplicationLogs();
      console.log(
        '------------ END : getTotalCountForApplicationLogsFromDB ********** ',
        this.totalNumberOfItemInDB,
      );
    },

    /**
     * Get all list of application logs from database.
     */
    async fetchAllApplicationLogsFromDB(paginationData) {
      console.log(
        '------------ START : fetching fetchAllApplicationLogsFromDB ********** ',
        paginationData,
      );
      this.dataList = await this.findAllApplicationLogs(paginationData);
      console.log(
        '------------ END : fetchAllApplicationLogsFromDB ********** ',
        this.dataList,
      );
    },

    /**
     * Emitter get trigger when user click on next/previous button on pagination bar.
     */
    async optionPaginationEmitter(options) {
      console.log('=========== Option : emitter START ===========', options);
      this.fetchAllApplicationLogsFromDB({
        pageNo: options.page,
        pageSize: options.itemsPerPage,
        sortBy: 'createdDate',
      });
      console.log('=========== option : emitter END ===========');
    },

  },
};
</script>
